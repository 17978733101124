import styled from '@emotion/styled'
import { css } from '@emotion/core'
import Img from 'gatsby-image'
import mq from '../../utils/styling'
import profilePhoto from '../../images/david.png'

export const Container = styled.div`
  font-size: 0.8rem;
  line-height: 30px;
  margin: auto 20px;
  ${mq.large(css`
    font-size: 1rem;
    line-height: 36px;
    max-width: 1180px;
    margin: 0 auto;
  `)};
`

export const LinkRed = styled.span`
  box-shadow: inset 0 -1px 0 #333;
  color: #333;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  &:hover {
    box-shadow: inset 0 -1px 0 #f85649;
    color: #f85649;
  }
`

export const LinkGold = styled.span`
  display: grid;
  width: 100%;
  // box-shadow: inset 0 -1px 0 #333;
  color: #333;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  &:hover {
    box-shadow: inset 0 -1px 0 #ffa447;
    color: #ffa447 !important;
  }
`

export const LinkGoldClean = styled.span`
  display: grid;
  width: 200px;
  color: #333;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  &:hover {
    color: #ffa447 !important;
  }
`

export const LinkGoldCleanAllPosts = styled.span`
  display: grid;
  width: 200px;
  color: #333;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  text-decoration: underline;
  &:hover {
    color: #ffa447 !important;
  }
`

export const Description = styled.span`
  margin-bottom: 10px;
`

export const Header = styled.h1`
  font-weight: normal;
  margin: 0;
  font-size: 1.4rem;
  border-bottom: 1px solid;
  margin-bottom: 1rem;
  ${mq.large(css`
    font-size: 1.8rem;
    border-top: 1px solid;
    margin-bottom: 30px;
    padding: 5px 10px;
  `)};
`

export const SubHeader = styled.div`
  font-family: 'Roboto';
  font-size: 1rem;
  font-weight: 600;
  color: rgb(0, 0, 0);
`

export const HeaderPost = styled.h1`
  line-height: 30px;
  text-align: center;
  letter-spacing: 0.5px;
  font-size: 1.5rem;
  max-width: 700px;
  font-weight: 700;
  margin: 30px 0;
  ${mq.large(css`
    font-size: 2rem;
    line-height: 35px;
    margin-bottom: 15px;
    margin: 50px 0;
    letter-spacing: 4px;
  `)};
`

export const PostHeaderContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  text-transform: uppercase;
  border-bottom: 1px solid black;
  margin-bottom: 30px;
  padding-bottom: 20px;
  ${mq.large(css`
    margin-bottom: 50px;
  `)};
`

export const PostSnippet = styled.div`
  font-size: 0.8rem;
  margin-bottom: 2rem;
  line-height: 30px;
  border-bottom: 1px solid;
  margin-bottom: 1.7rem;
  ${mq.large(css`
    margin-bottom: 3rem;
    padding-bottom: 1.9;
    font-size: 1rem;
    display: block;
    text-align: center;
  `)};
`

export const PostSnippetTitle = styled.h3`
  font-size: 1rem;
  font-weight: 500;
  margin: 0.3rem;
  text-align: center;
  ${mq.medium(css`
    font-size: 1.2rem;
    margin: 0.5rem;
  `)};
  ${mq.large(css`
    margin-bottom: 1rem;
    font-size: 1.7rem;
    margin: 1rem;
  `)};
`

export const PostSnippetDescription = styled.div`
  text-align: left;
  margin: 0 30px 1.2rem 1.2rem;
  display: none;
  ${mq.large(css`
    margin-bottom: 1.5rem;
    display: block;
  `)};
`

export const PostIconsContainer = styled.div`
  font-size: 0.6rem;
  text-align: center;
  ${mq.large(css`
    font-size: 0.8rem;
    margin-bottom: 0.7rem;
    display: block;
  `)};
`

export const Image = styled.div`
  background-image: url(${profilePhoto});
  height: 50px;
  padding-top: 6px;
  width: 50px;
  background-size: cover;
  border-radius: 50%;
  margin-left: 10px;
  display: none;
  ${mq.large(css`
    height: 50px;
    width: 50px;
    margin-left: 20px;
    display: block;
  `)};
`

export const LargeImage = styled.div`
  background-image: url(${profilePhoto});
  height: 50px;
  padding-top: 6px;
  width: 50px;
  background-size: cover;
  border-radius: 50%;
  display: none;
  ${mq.large(css`
    height: 90px;
    width: 90px;
    margin-left: 20px;
    display: block;
  `)};
`

export const PostSnippetHeader = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
  font-size: 0.7rem;
  ${mq.large(css`
    font-size: 1rem;
    margin-bottom: 40px;
  `)};
`

export const PostSnippetImage = styled(Img)`
  width: 60%;
  margin: auto;
  ${mq.large(css`
    width: 60%;
  `)};
`

export const CoverImage = styled(Img)`
  border-bottom: 1px solid black;
  margin-bottom: 12px;
  width: 100%;
  height: 140px;
  ${mq.large(css`
    height: 100%;
    margin-bottom: 30px;
  `)};
`

export const CoverImageWrapper = styled.div`
  display: flex;
  justify-content: center;
`

export const MenuContainer = styled.div`
  display: none;
  ${mq.medium(css`
    display: block;
    background-color: white;
  `)};
  ${mq.large(css`
    display: block;
    background-color: white;
  `)};
`

export const DropdownMenuContainer = styled.div`
  text-align: center;
  border-top: 1px solid black;
  border-bottom: 1px solid black;
  padding: 4px 0;
  height: 50px;
`

export const TextLogo = styled.div`
  font-family: Oranienbaum;
  display: flex;
  justify-content: center;
  letter-spacing: 9px;
  font-size: 32px;
  margin: 25px 0;
  text-transform: uppercase;
  ${mq.medium(css`
    font-size: 45px;
    margin: 35px 0;
  `)};
  ${mq.large(css`
    font-size: 52px;
    margin: 50px 0;
  `)};
`

export const PostContainer = styled.div`
  margin: 0 30px;
  ${mq.xLarge(css`
    margin: 0;
  `)};
`

/* talks page */

/*
export const Break = styled.hr`
  color: black;
  background-color: black;
  max-width: 100%;
  height: 5px;
  overflow: hidden;
  position: relative;
  margin: 0;
` 

export const StyledBtn = styled.a`
  border: 2px solid #000;
  border-bottom-width: 7px;
  box-shadow: inset 0 -1px 0 #000, 5px 5px 0 rgba(0, 96, 193, 0.25),
    -5px -5px 0 rgba(0, 96, 193, 0.25);
  color: #000;
  display: inline-block;
  font-weight: bold;
  overflow: hidden;
  padding: 5px 4px 2px;
  position: relative;
  transition: box-shadow 0.4s ease-in-out;

  &:before,
  &:after {
    content: '▶';
    display: inline-block;
    position: relative;
    text-align: center;
    top: 1px;
    width: 26px;
  }

  &:after {
    content: '◀';
  }

  &:hover {
    box-shadow: inset 0 -300px 0 #fff, 5px 5px 0 rgba(0, 96, 193, 0.25),
      -5px -5px 0 rgba(0, 96, 193, 0.25);
    border-bottom-width: 7px;
    padding-bottom: 2px;
  }
`

export const LinkBlue = styled.a`
  margin-right: 1.6rem;
  box-shadow: inset 0 -5px 0 rgba(0, 96, 193, 0.25);
  color: black;
  -webkit-transition: box-shadow 0.4s ease-in-out;
  transition: box-shadow 0.4s ease-in-out;
  &:hover {
    box-shadow: inset 0 -300px 0 rgba(0, 96, 193, 0.25);
  }
`

export const Message = styled.div`
  margin: 1rem;
  ${mq.large(css`
    margin-bottom: 2rem;
  `)};
`

export const MessageContainer = styled.div`
  margin-bottom: 1rem;
  text-align: center;
  ${mq.large(css`
    margin-bottom: 2rem;
  `)};
`

export const StyledBreak = styled.hr`
  margin: 1rem 0;
  ${mq.large(css`
    margin: 1.5rem 0;
  `)};
`

export const TalksList = styled.ul`
  list-style: none;
  margin: 0;
`

export const TalkTitle = styled.li`
  font-size: 0.9rem;
  ${mq.large(css`
    font-size: 1.1rem;
  `)};
`



*/
